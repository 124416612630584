<template>
  <WeCard class="border-radius-9 h-100">
    <div class="row align-items-center mb-3">
      <div class="col">
        <h5 class="mb-0">Ortalama Sepet Tutarı</h5>
      </div>
      <div class="col-auto">
        <span class="btn btn-light btn-sm border-0" v-on:click="onRefresh"
          ><i class="fas fa-sync"></i
        ></span>
      </div>
    </div>

    <div class="position-relative" style="min-height: 370px" v-if="loading">
      <WeLoading />
    </div>
    <div id="chart" v-else>
      <apexchart
        type="line"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </WeCard>
</template>
<script>
import ApexCharts from "vue-apexcharts";
import { mapState, mapActions } from "vuex";

export default {
  name: "CartAverage",
  components: {
    apexchart: ApexCharts,
  },
  data() {
    return {
      name: "cartAverage",
      loading: false,
      series: [
        {
          name: "Sepet Tutarı",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          locales: [
            {
              name: "tr",
              options: {
                months: [
                  "Ocak",
                  "Şubat",
                  "Mart",
                  "Nisan",
                  "Mayıs",
                  "Haziran",
                  "Temmuz",
                  "Ağustos",
                  "Eylül",
                  "Ekim",
                  "Kasım",
                  "Aralık",
                ],
                shortMonths: [
                  "Oca",
                  "Şub",
                  "Mar",
                  "Nis",
                  "May",
                  "Haz",
                  "Tem",
                  "Ağu",
                  "Eyl",
                  "Eki",
                  "Kas",
                  "Ara",
                ],
                days: [
                  "Pazar",
                  "Pazartesi",
                  "Salı",
                  "Çarşamba",
                  "Perşembe",
                  "Cuma",
                  "Cumartesi",
                ],
                shortDays: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
                toolbar: {
                  exportToSVG: "SVG İndir",
                  exportToPNG: "PNG İndir",
                  exportToCSV: "CSV İndir",
                  menu: "Menü",
                  selection: "Seçim",
                  selectionZoom: "Seçim Yakınlaştır",
                  zoomIn: "Yakınlaştır",
                  zoomOut: "Uzaklaştır",
                  pan: "Kaydır",
                  reset: "Yakınlaştırmayı Sıfırla",
                },
              },
            },
          ],
          defaultLocale: "tr",
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: [],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        tooltip: {
          x: {
            show: false,
            // formatter: function (value, series) {
            //   const date = new Date(); // 2009-11-10
            //   const month = date.toLocaleString("tr-TR", { month: "long" });

            //   return `${value} ${month}`;
            // },
          },
          y: {
            formatter: function(value, series) {
              // use series argument to pull original string from chart data
              return localization.getMoneyFormat(null, value);
            },
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return localization.getMoneyFormat(null, value);
            },
          },
        },
      },
    };
  },
  methods: {
    ...mapActions("widget", ["refreshReport"]),
    onRefresh(showLoader = true) {
      this.loading = showLoader;

      this.refreshReport({
        name: this.name,
        onSuccess: (result) => {
          const items = result.data.items || [];
          this.series[0].data = helper.arrayPluck(items, "avg");
          this.chartOptions.xaxis.categories = helper.arrayPluck(items, "date");

          this.loading = false;
        },
      });
    },
  },
  computed: {
    ...mapState({
      report: (state) => state.widget.reports,
    }),
  },
  mounted() {
    this.onRefresh();
  },
  watch: {
    "report.period": function(newVal) {
      if (newVal && newVal !== "custom_date") {
        this.onRefresh(true);
      }
    },
    "report.periodDate": function(newVal) {
      if (newVal && newVal.start && newVal.end) {
        this.onRefresh(true);
      }
    },
  },
};
</script>
